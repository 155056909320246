import React from "react";
import styled from "styled-components";
import { Link, graphql } from "gatsby";

// Services
import intl from "../../services/intl";

// Utils
import { rem, Color, Font, responsive } from "../../utils/style";

// Components
import Img from "gatsby-image";
import PageSEO from "../../components/seo/Page";
import Row from "../../components/Row";
import Container from "../../components/Container";
import ScrollMagicController from "../../components/ScrollMagicController";
import ApproachImageAnimation from "../../components/ApproachImageAnimation";
import ScienceTeam from "../../components/approach/ScienceTeam";
import Experts from "../../components/product/Experts";
import Circle from "../../components/home/Circle";
import ProductCategoryCards from "../../components/product/ProductCategoryCards";
import PageHero from "../../components/PageHero";
import Text from "../../components/Text";

// Styled Elements
const StyledLink = styled(Link)`
  transition: opacity 0.2s;
  border-bottom: 1px solid ${Color.ritualBlue};

  &:hover {
    opacity: 0.56;
  }
`;

const HeroContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    ${Font.circular};
    font-weight: 500;
    color: ${Color.white};
    margin: 0;

    font-size: ${rem(52)};
    line-height: ${rem(46)};
    letter-spacing: -1.16px;
    padding-bottom: 17px;
    border-bottom: 7px solid ${Color.white};

    span {
      display: block;
      padding-left: 65px;
    }

    ${responsive.sm`
      font-size: ${rem(72.2)};
      line-height: ${rem(63.54)};
      letter-spacing: -1.38px;
      border-bottom: 10px solid ${Color.white};
      margin-top: 20px;

      span {
        padding-left: 95px;
      }
    `};

    ${responsive.lg`
      font-size: ${rem(95)};
      line-height: ${rem(83.6)};
      letter-spacing: -1.82px;
      padding-bottom: 23px;
      border-bottom: 12px solid ${Color.white};
      margin-top: 40px;

      span {
        padding-left: 120px;
      }
    `};
  }
`;

const FounderStatement = styled.section`
  background-color: #f6f4f9;
  padding: 80px 0;
  position: relative;
  overflow: hidden;

  ${responsive.md`
    padding: 120px 0;
  `};
`;

const FounderStatementTitle = styled.h2`
  ${Font.circular};
  color: ${Color.ritualBlue};
  text-align: center !important;
  font-size: ${rem(30)};
  line-height: ${rem(36)};
  letter-spacing: -0.4px;
  margin: 0 auto 40px;
  width: 280px;

  ${responsive.sm`
    width: 100%;
  `};

  ${responsive.md`
    width: 613px;
    font-size: ${rem(48)};
    line-height: ${rem(54)};
    letter-spacing: -1.4px;
    margin-bottom: 56px;
  `};

  ${responsive.lg`
    width: 770px;
  `};
`;

const FounderStatementContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  p {
    ${Font.dutch};
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    font-weight: normal;
    margin: 0 0 16px;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      margin-bottom: 24px;
    `};

    &:last-child {
      ${Font.circular};
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      font-weight: 500;
      margin: 0;

      ${responsive.md`
        font-size: ${rem(22)};
        line-height: ${rem(32)};
        letter-spacing: -0.2px;
      `};

      span {
        font: inherit;
        display: block;
      }
    }
  }
`;

const FounderWrapper = styled.div`
  align-items: center;

  ${responsive.sm`
    align-items: flex-start;
  `};
`;

const Kat = styled.img`
  width: 182px;
  max-width: 100%;
  height: auto;
  margin: 0 auto 24px;

  ${responsive.sm`
    width: 221px;
    max-width: calc(100% + 10px);
    margin: 0;
  `};

  ${responsive.md`
    width: 296px;
  `};

  ${responsive.lg`
    width: 370px;
    max-width: 100%;
  `};
`;

const LeadershipSection = styled.section`
  padding: 80px 0;
  position: relative;
  overflow: hidden;

  ${responsive.md`
    padding: 120px 0;
  `};
`;

const LeadershipColumn = styled.div.attrs({
  className: "col-12 text-center",
})`
  z-index: 10;

  > h3 {
    ${Font.circular};
    text-align: center !important;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin: 0 auto 16px;
    max-width: 280px;

    ${responsive.sm`
      max-width: 580px;
    `};

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin: 0 auto 24px;
      max-width: 774px;
    `};

    ${responsive.lg`
      max-width: 100%;
    `};
  }

  > p {
    ${Font.dutch};
    margin: 0 auto 40px;
    max-width: 280px;
    line-height: ${rem(26)};

    ${responsive.sm`
      max-width: 459px;
    `};

    ${responsive.md`
      max-width: 614px;
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      margin-bottom: 56px;
    `};

    ${responsive.lg`
      max-width: 770px;
    `};
  }
`;

const LeadershipImageWrapper = styled.div`
  background-color: #ccc;
  /* height: 262px; */
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 24px;

  ${responsive.sm`
    width: 580px;
    margin: 0 auto 16px;
  `};

  ${responsive.md`
    width: 774px;
    margin-bottom: 24px;
  `};

  ${responsive.lg`
    width: 970px;
  `};
`;

const LeadershipNames = styled.div`
  margin-bottom: 80px;

  ${responsive.sm`
    display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
  `};

  ${responsive.md`
    margin-bottom: 120px;
    margin-left: 0;
    margin-right: 0;
    justify-content: center;
  `};

  p {
    ${Font.circular};
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin: 0 0 12px;
    text-align: left;

    ${responsive.sm`
      text-align: center;
      font-size: ${rem(16)};
      line-height: ${rem(26)};
      margin: 0 10px;
      width: 160px;
    `};

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      width: 170px;
      margin: 0 15px;
    `};

    &.pictured {
      font-size: ${rem(12)};
      text-transform: uppercase;
      letter-spacing: 0.8px;
      color: rgba(20, 43, 111, 0.56);
      margin-bottom: 14px;

      ${responsive.sm`
        display: none;
      `};
    }

    span {
      ${Font.dutch};
      font-weight: normal;

      &:before {
        ${Font.circular};
        content: ", ";

        ${responsive.sm`
          display: none;
        `};
      }

      ${responsive.sm`
        display: block;
        margin: 10px 0 0;
      `};

      ${responsive.md`
        margin-top: 8px;
      `};
    }
  }
`;

const ScienceTeamColumn = styled.div.attrs({
  className: "col-12 text-center",
})`
  > h3 {
    ${Font.circular};
    text-align: center !important;
    font-size: ${rem(30)};
    line-height: ${rem(36)};
    letter-spacing: -0.4px;
    margin: 0 auto 16px;
    max-width: 280px;

    ${responsive.sm`
      max-width: 100%;
    `};

    ${responsive.md`
      font-size: ${rem(48)};
      line-height: ${rem(54)};
      letter-spacing: -1.4px;
      margin: 0 auto 24px;
    `};
  }

  > p {
    ${Font.dutch};
    margin: 0 auto 40px;
    max-width: 280px;
    line-height: ${rem(26)};

    ${responsive.sm`
      max-width: 459px;
    `};

    ${responsive.md`
      max-width: 614px;
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      margin-bottom: 56px;
    `};

    ${responsive.lg`
      max-width: 770px;
    `};
  }
`;

export default class Approach extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      controller: null,
      seo: {
        pagePath: "approach",
        title: "Who We Are - Ritual",
        description:
          "Committed to creating the purest, most effective vitamin formulations, and sharing every ingredient, source & scientist that made our vitamin a reality.",
      },
    };
    this.controller = null;
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Who We Are",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });
  }

  componentWillUnmount() {
    if (this.controller) {
      this.controller.destroy();
    }
  }

  render() {
    let { controller } = this;
    const { data } = this.props;

    let scienceTeam = {
      nima: data.nima,
      mastaneh: data.mastaneh,
      kevin: data.kevin,
    };

    let expertsTitle = intl.t(
      "approach.experts-title",
      "Scientific Advisory Board",
    );
    let expertsSubhead = intl.t(
      "approach.experts-subhead",
      "Our scientific advisory board, comprised of medical doctors, nutritionists and scientists, helps guide our thinking around the latest research and product innovation.",
    );
    let experts = [
      data.expertMarie,
      data.expertArne,
      data.expertJason,
      data.expertRobin,
      data.expertMichelle,
      data.expertJordan,
      data.expertBlanche,
      data.expertNima,
      data.expertLuke,
      data.expertMastaneh,
    ];

    let images = [
      data.approachImage1,
      data.approachImage2,
      data.approachImage3,
    ];

    const foundersHeadline = intl.t(
      "approach.founders-headline",
      "Created by a woman who wouldn’t settle for less than the truth.",
    );

    return (
      <>
        <PageSEO {...this.state.seo} />

        <div id="approach" className="page">
          <PageHero
            backgroundColor={"#ceced2"}
            imageMobile={data.heroImageMobile}
            imageDesktop={data.heroImageDesktop}
          >
            <HeroContent className="d-flex align-items-center justify-content-center">
              <h1>
                {intl.t("approach.hero.heading.line1", "It’s time")}
                <br />
                {intl.t("approach.hero.heading.line2", "to clear")}
                <span>{intl.t("approach.hero.heading.line3", "up the")}</span>
                {intl.t("approach.hero.heading.line4", "facts")}
              </h1>
            </HeroContent>
          </PageHero>

          <ScrollMagicController
            ref={r => {
              r && (this.controller = r.controller);
            }}
          />

          {controller && (
            <ApproachImageAnimation images={images} controller={controller} />
          )}

          <FounderStatement>
            <Container>
              <Row>
                <div className="col-12 col-sm-10 offset-sm-1">
                  <FounderStatementTitle>
                    {foundersHeadline}
                  </FounderStatementTitle>
                </div>
                <FounderWrapper className="col-12 col-sm-4 offset-sm-1 d-flex">
                  <Kat src="/images/katerina_yellow.jpg" alt="Katerina" />
                </FounderWrapper>
                <FounderStatementContent className="col-12 col-sm-6 col-md-6">
                  <p>
                    <Text
                      id="approach.founder.copy1"
                      defaultMessage={`When I was pregnant, I turned my house upside down to get
                    rid of products with ingredients I couldn't get behind. I
                    replaced everything from my cleaning supplies to my
                    deodorant. But, in my search for my ideal {link}, I found questionable ingredients.
                    I couldn't find a brand
                    that I trusted, so I decided to build my own.`}
                      values={{
                        link: (
                          <StyledLink to="/products/essential-prenatal-multivitamin">
                            <Text
                              id="approach.founder.copy1-link"
                              defaultMessage="prenatal vitamin"
                            />
                          </StyledLink>
                        ),
                      }}
                    />
                  </p>
                  <p>
                    <Text
                      id="approach.founder.copy2"
                      defaultMessage={`I founded Ritual with the belief that better health begins
                        with better ingredients. With us, you’ll know where
                        ingredients come from and why they're there.`}
                    />
                  </p>
                  <p>
                    <Text
                      id="approach.founder.copy3"
                      defaultMessage={`Welcome to your new daily ritual.`}
                    />
                  </p>
                  <p>
                    <Text
                      id="approach.founder.copy4"
                      defaultMessage={`Katerina Schneider`}
                    />
                    <span>
                      <Text
                        id="approach.founder.copy5"
                        defaultMessage={`CEO & Founder`}
                      />
                    </span>
                  </p>
                </FounderStatementContent>
              </Row>
            </Container>

            {controller && (
              <Circle
                controller={controller}
                className="bottom right experts"
              />
            )}
          </FounderStatement>

          <LeadershipSection>
            {controller && (
              <Circle controller={controller} className="top right" />
            )}
            <Container>
              <Row>
                <LeadershipColumn>
                  <h3>
                    <Text
                      id="approach.leadership.heading"
                      defaultMessage={`Scientists, Mothers, Researchers, and More`}
                    />
                  </h3>
                  <p>
                    <Text
                      id="approach.leadership.copy"
                      defaultMessage={`Meet the women on our leadership team. We’re obsessed with
                      making products we want to see in the world and for
                      ourselves.`}
                    />
                  </p>
                  <LeadershipImageWrapper>
                    <Img
                      className="d-sm-none"
                      fluid={data.teamPhotoMobile.fluid}
                      loading="eager"
                      alt="The women of Ritual's leadership team"
                    />
                    <Img
                      className="d-none d-sm-block"
                      fluid={data.teamPhotoDesktop.fluid}
                      loading="eager"
                      alt="The women of Ritual's leadership team"
                    />
                  </LeadershipImageWrapper>
                  <LeadershipNames>
                    <p className="pictured">
                      <Text
                        id="approach.leadership.names.heading"
                        defaultMessage={`Pictured, from left to right`}
                      />
                    </p>
                    <p>
                      Liz Reifsnyder
                      <span>
                        <Text
                          id="approach.leadership.names.liz"
                          defaultMessage={`COO`}
                        />
                      </span>
                    </p>
                    <p>
                      Katerina Schneider
                      <span>
                        <Text
                          id="approach.leadership.names.kat"
                          defaultMessage={`CEO & Founder`}
                        />
                      </span>
                    </p>
                    <p>
                      Lauren Hayes
                      <span>
                        <Text
                          id="approach.leadership.names.lauren"
                          defaultMessage={`VP, Community & Partnerships`}
                        />
                      </span>
                    </p>
                    <p>
                      Kadie Bowen
                      <span>
                        <Text
                          id="approach.leadership.names.kadie"
                          defaultMessage={`SVP of People Ops and Program Management`}
                        />
                      </span>
                    </p>
                  </LeadershipNames>
                </LeadershipColumn>
                <ScienceTeamColumn>
                  <h3>
                    <Text
                      id="approach.scientific.names.heading"
                      defaultMessage={`Our Scientific Leadership Team`}
                    />
                  </h3>
                  <p>
                    <Text
                      id="approach.scientific.copy"
                      defaultMessage={`What happens when a Harvard trained physiologist, a
                      biochemist, and a registered dietician walk into a lab? {link}.`}
                      values={{
                        link: (
                          <StyledLink to="/products/essential-for-women-multivitamin">
                            <Text
                              id="approach.scientific.copy-link"
                              defaultMessage="Essential for Women"
                            />
                          </StyledLink>
                        ),
                      }}
                    />
                  </p>

                  <ScienceTeam team={scienceTeam} />
                </ScienceTeamColumn>
              </Row>
            </Container>
          </LeadershipSection>

          <Experts
            arrowsEnabled={true}
            expertsTitle={expertsTitle}
            expertsSubhead={expertsSubhead}
            experts={experts}
          />

          <ProductCategoryCards className="mt-7 mt-md-9 mb-7 mb-md-9" />
        </div>
      </>
    );
  }
}

export const pageQuery = graphql`
  query ApproachQuery($locale: String!) {
    teamPhotoDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "wqQEwrKVvQfLQYv3v3k9r" }
    ) {
      description
      id
      fluid(maxWidth: 970, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    teamPhotoMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1JlL53MEfAXesLiIjfInlU" }
    ) {
      description
      id
      fluid(maxWidth: 768, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    nima: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3cqgtgSHLV1OAnJEvFOl4C" }
    ) {
      node_locale
      description
      id
      fluid(maxWidth: 270, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    kevin: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2wFjtcpyTNCh2TdTuXMP2n" }
    ) {
      description
      id
      fluid(maxWidth: 270, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    mastaneh: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "46pBOPw2DxrYw7ixUOCw3B" }
    ) {
      description
      id
      fluid(maxWidth: 270, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    approachImage1: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "7nSACZPzxCdQwGQTgortLC" }
    ) {
      description
      id
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    approachImage2: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "6ZWj1R67Zv0PlVWc622P2J" }
    ) {
      description
      id
      fluid(maxWidth: 570, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    approachImage3: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "4xvZH3f69VSWSF9ZiCIEbE" }
    ) {
      description
      id
      fluid(maxWidth: 1710, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    expertArne: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "65alR4mn36Twf2UVuZnue2" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertJason: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5axd7XLfTqWO42aYECsOAm" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertMarie: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "3FvwhjvmkweGe6AOC8CwK0" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertRobin: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1hRXD11SpeUAoIqic6EIwi" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertMichelle: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "78cQfx6E7umsSUImkq668Y" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertJordan: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "6K2N1ofcNaOCeCOomWKKUg" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertBlanche: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2TEOLtZANWysoCuQGCq0Yy" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertNima: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "4EiuomdkEwEE0gkec40QsY" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertLuke: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "1jfUYHAHH4iKuaemWQWq8K" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    expertMastaneh: contentfulExpert(
      node_locale: { eq: $locale }
      contentful_id: { eq: "2MB9ZRTfsccawGiwoEKq46" }
    ) {
      name
      title
      image {
        description
        fluid(maxWidth: 80, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    heroImageMobile: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "5pyxSWI9bAggYzkDMqc0hY" }
    ) {
      description
      id
      fluid(maxWidth: 912, quality: 80) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    heroImageDesktop: contentfulAsset(
      node_locale: { eq: $locale }
      contentful_id: { eq: "6ku42oaGnrr5dPZLxlJXLH" }
    ) {
      description
      id
      fluid(maxWidth: 1920, quality: 80) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
